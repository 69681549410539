<template>
    <div class="flex items-center inline-flex">
    <span class="text-botonT font-bold mr-2">{{campoTitulo}}</span>
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: "campo",
  props:["campoTitulo"]
  
};
</script>
