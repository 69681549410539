export default {
	methods: {
		montoCobrarBsUsd(arg) {
			arg.MontoCobrarBs = this.MontoCobrarBss();
			arg.MontoCobrarD = this.MontoCobrarDd();
		},
		async usuariosQuery() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: require("@/graphql/queries/usuarios.gql")
				})
				.then(data => {
					this.$store.state.isLoading = false;
					this.usuarios = data.data.usuarios;
					this.usuariosArraySelect = this.usuarios.map(x => {
						return x.name;
					});
					this.numeroCliente = this.usuarios.map(x => {
						return x.numero_telefono_movil;
					});
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.log(error);
				});
		},
		async listaVendedoresQuery() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: require("@/graphql/queries/listaVendedoresPedido.gql")
				})
				.then(data => {
					this.$store.state.isLoading = false;
					this.vendedoress = data.data.listaVendedoresPedidos;
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.log(error);
				});
		},
		utilidadAproximada() {
			this.utilidadAproximadaUSD = this.productosCotizacionT.reduce(function (
				sum,
				product
			) {
				var lineTotal =
					parseFloat(product.MontoCobrarD) *
					(1 - parseFloat(product.Descuento) / 100);
				if (!isNaN(lineTotal)) {
					return sum + lineTotal;
				}
			},
				0);

			this.utilidadAproximadaBs = this.utilidadAproximadaUSD * this.tasadia;
		},
		addNewRow() {
			if (
				this.productosCotizacion[0].Cantidad != "" &&
				this.productosCotizacion[0].Producto != ""
			) {
				if (this.productosCotizacion[0].Comentario === "") {
					this.productosCotizacion[0].Comentario = "NA";
				}
				this.productosCotizacionT.push({
					Cantidad: this.productosCotizacion[0].Cantidad,
					Rubro: this.productosCotizacion[0].Rubro,
					Unidad: this.productosCotizacion[0].Unidad,
					Producto: this.productosCotizacion[0].Producto,
					PrecioUnitarioBs: this.productosCotizacion[0].PrecioUnitarioBs,
					PrecioUnitarioD: this.productosCotizacion[0].PrecioUnitarioD,
					MontoCobrarBs: this.productosCotizacion[0].MontoCobrarBs,
					MontoCobrarD: this.productosCotizacion[0].MontoCobrarD,
					Comentario: this.productosCotizacion[0].Comentario,
					consultarVendedor: this.consultarVendedor,
					Vendedor: this.productosCotizacion[0].Vendedor,
					Descuento: this.productosCotizacion[0].Descuento,
					Idprecio: this.productosCotizacion[0].Idprecio,
					ivabs: this.productosCotizacion[0].ivabs,
					ivad: this.productosCotizacion[0].ivad,
					tasa_proveedores: this.productosCotizacion[0].tasa_proveedores
				});
				this.productosCotizacion[0].Cantidad = "";
				this.productosCotizacion[0].Unidad = "";
				this.productosCotizacion[0].Producto = "";
				this.productosCotizacion[0].PrecioUnitarioBs = "";
				this.productosCotizacion[0].PrecioUnitarioD = "";
				this.productosCotizacion[0].MontoCobrarBs = "";
				this.productosCotizacion[0].MontoCobrarD = "";
				this.productosCotizacion[0].Comentario = "";
				this.productoId = "";
				this.productosCotizacion[0].ivabs = "";
				this.productosCotizacion[0].ivad = "";
				this.productosCotizacion[0].tasa_proveedores = "";
				this.consultarVendedor = false;
				this.$refs.selectRubro.focus();
			} else {
				alert("Se encuentran campos vacios");
			}
		},
		async precioPorproducto(argu) {
			this.message = "";
			if (argu != "") {
				for (let j in this.productoObjeto) {
					if (argu == this.productoObjeto[j].producto) {
						this.productoId = this.productoObjeto[j].id;
						break;
					}
				}
			}
			if (this.productoId != "") {
				this.message = "Cargando...";
				this.$store.state.isLoading = true;
				await this.$apollo
					.query({
						query: require("@/graphql/queries/precioCotizacion.gql"),
						variables: {
							id: this.productoId
						},
						fetchPolicy: "no-cache"
					})
					.then(data => {
						this.$store.state.isLoading = false;
						let productoDisponible = false;
						let productoNoRegistrado = true;
						let tasaProveedoresArray = [];
						let tasaProveedorDolar = 0;
						let precioProductoUSD = 0;
						let precioProductoBs = 0;
						let iva = 0;
						let Idprecio = 0;
						if (data.data.precioCotizacion.length >= 1) {
							productoNoRegistrado = false;
							for (
								let index = 0;
								index < data.data.precioCotizacion.length;
								index++
							) {
								if (
									parseFloat(data.data.precioCotizacion[index].precio_usd) >
									0 &&
									data.data.precioCotizacion[index].existencia === "1" &&
									data.data.precioCotizacion[index].local[0].show === "1" &&
									data.data.precioCotizacion[0].unidad ===
									data.data.precioCotizacion[index].unidad
								) {
									productoDisponible = true;
									Idprecio = data.data.precioCotizacion[index].id;
									//precio mayor del producto
									if (precioProductoUSD === 0) {
										precioProductoUSD = data.data.precioCotizacion[index].precio_usd;
										precioProductoBs = data.data.precioCotizacion[index].precio_bs;
										iva = data.data.precioCotizacion[index].iva;
									}
								}
								if (data.data.precioCotizacion[index].local[0].tasa_dia > 0) {
									tasaProveedorDolar =
										data.data.precioCotizacion[index].local[0].tasa_dia;
								} else {
									tasaProveedorDolar = this.tasadia;
								}
								tasaProveedoresArray.push({
									id: data.data.precioCotizacion[index].local[0].id,
									tasa_dia: tasaProveedorDolar,
									precios_id: data.data.precioCotizacion[index].id,
									precio_dia_bs: data.data.precioCotizacion[index].precio_bs
								});
							}
						} else {
							this.message = "No hay proveedor asociado al producto.";
							this.productosCotizacion[0].Cantidad = "";
							this.productosCotizacion[0].PrecioUnitarioBs = "";
							this.productosCotizacion[0].PrecioUnitarioD = "";
							this.productosCotizacion[0].MontoCobrarBs = "";
							this.productosCotizacion[0].MontoCobrarD = "";
							this.productosCotizacion[0].Comentario = "";
							this.productosCotizacion[0].Descuento = "";
							this.productosCotizacion[0].ivabs = "";
							this.productosCotizacion[0].ivad = "";
							this.productosCotizacion[0].tasa_proveedores = "";
							this.mostrarAnadirProducto = false;
							this.notificarVendedor = true;
							this.consultarVendedor = true;
						}
						if (!productoNoRegistrado) {
							if (productoDisponible) {
								this.productosCotizacion[0].PrecioUnitarioBs =
									precioProductoBs;
								this.productosCotizacion[0].PrecioUnitarioD =
									precioProductoUSD;
								this.productosCotizacion[0].Descuento =
									data.data.precioCotizacion[0].descuento;
								this.productosCotizacion[0].Unidad =
									data.data.precioCotizacion[0].unidad;
								this.productosCotizacion[0].Idprecio =
									Idprecio;
								this.productosCotizacion[0].iva =
									iva;
								this.productosCotizacion[0].tasa_proveedores = JSON.stringify(
									tasaProveedoresArray
								);
								data.data.precioCotizacion = "";
								this.message = "Completado";
								this.mostrarAnadirProducto = true;
								this.notificarVendedor = false;
								this.consultarVendedor = false;
								this.montoCobrarBsUsd(this.productosCotizacion[0]);
							} else {
								this.message = "Producto Agotado";
								this.productosCotizacion[0].Cantidad = "";
								this.productosCotizacion[0].PrecioUnitarioBs = "";
								this.productosCotizacion[0].PrecioUnitarioD = "";
								this.productosCotizacion[0].MontoCobrarBs = "";
								this.productosCotizacion[0].MontoCobrarD = "";
								this.productosCotizacion[0].Comentario = "";
								this.productosCotizacion[0].Descuento = "";
								this.productosCotizacion[0].ivabs = "";
								this.productosCotizacion[0].ivad = "";
								this.productosCotizacion[0].tasa_proveedores = "";
								this.mostrarAnadirProducto = false;
								this.notificarVendedor = true;
								this.consultarVendedor = true;
							}
						}
					})
					.catch(error => {
						this.$store.state.isLoading = false;
						this.message = error;
						this.showMessage = true;
					});
			}
		},
		async opcionesProducto(rubro) {
			if (rubro != "" && this.ultimoRubro != rubro) {
				this.rubroCompletado.push(rubro);
				this.message = "Cargando...";
				this.showMessage = true;
				this.$store.state.isLoading = true;
				await this.$apollo
					.query({
						query: require("@/graphql/queries/productoRubro.gql"),
						variables: {
							rubro: rubro
						}
					})
					.then(data => {
						this.nuevoProducto = [];
						this.productoObjeto = data.data.productoRubro;
						for (let i = 0; i < data.data.productoRubro.length; i++) {
							this.nuevoProducto.push(data.data.productoRubro[i].producto);
						}
						this.productoObjeto[rubro] = data.data.productoRubro;
						this.selectRefresh = !this.selectRefresh;
						this.ultimoRubro = rubro;
						this.message = "Completado";
						this.showMessage = true;
						this.$store.state.isLoading = false;
						return this.nuevoProducto;
					})
					.catch(error => {
						this.$store.state.isLoading = false;
						this.message = error;
						this.showMessage = true;
					});
			} else {
				this.ultimoRubro = rubro;
				return this.nuevoProducto;
			}
		},
		calcularTotalbs() {
			let subtotal, total, ivabs;
			ivabs = this.productosCotizacionT.reduce(function (sum, product) {
				var lineTotal = parseFloat(product.ivabs);
				if (!isNaN(lineTotal)) {
					return sum + lineTotal;
				}
			}, 0);
			this.ivabs = ivabs.toFixed(2);
			subtotal = this.productosCotizacionT.reduce(function (sum, product) {
				var lineTotal = parseFloat(product.MontoCobrarBs);
				if (!isNaN(lineTotal)) {
					return sum + lineTotal;
				}
			}, 0);
			this.subtotalbs = subtotal.toFixed(2);
			total = ivabs + subtotal;
			total = parseFloat(total);
			if (
				!isNaN(total) &&
				this.vendedorAsignado != "" &&
				this.numeroTlf != ""
			) {
				this.totalbs = total.toFixed(2);
				this.show = true;
				this.botones = true;
			} else {
				this.totalbs = "0.00";
			}
		},
		calcularTotalusd() {
			var subtotal, total, ivad;
			ivad = this.productosCotizacionT.reduce(function (sum, product) {
				var lineTotal = parseFloat(product.ivad);
				if (!isNaN(lineTotal)) {
					return sum + lineTotal;
				}
			}, 0);
			this.ivad = ivad.toFixed(5);
			subtotal = this.productosCotizacionT.reduce(function (sum, product) {
				var lineTotal = parseFloat(product.MontoCobrarD);
				if (!isNaN(lineTotal)) {
					return sum + lineTotal;
				}
			}, 0);
			this.subtotalusd = subtotal.toFixed(5);
			total = ivad + subtotal;
			total = parseFloat(total);
			if (!isNaN(total)) {
				this.totalusd = total.toFixed(5);
			} else {
				this.totalusd = "0.00";
			}
		},
		MontoCobrarBss() {
			let argu = "";
			argu =
				parseFloat(this.productosCotizacion[0].PrecioUnitarioBs) *
				parseFloat(this.productosCotizacion[0].Cantidad);
			this.productosCotizacion[0].ivabs =
				(argu * this.productosCotizacion[0].iva) / 100;
			return argu;
		},
		MontoCobrarDd() {
			let argu = "";
			argu =
				parseFloat(this.productosCotizacion[0].PrecioUnitarioD) *
				parseFloat(this.productosCotizacion[0].Cantidad);
			this.productosCotizacion[0].ivad =
				(argu * this.productosCotizacion[0].iva) / 100;
			return argu;
		},
		notificarVendedorMutation: async function () {
			if (this.productosCotizacion[0].Vendedor) {
				this.idVendedor();
				alert("Vendedor notificado");
				this.notificarVendedorBroadcastingNuevoProducto();
				this.productosCotizacion[0].Cantidad = "";
				this.productosCotizacion[0].Unidad = "";
				this.productosCotizacion[0].Producto = "";
				this.productosCotizacion[0].PrecioUnitarioBs = "";
				this.productosCotizacion[0].PrecioUnitarioD = "";
				this.productosCotizacion[0].MontoCobrarBs = "";
				this.productosCotizacion[0].MontoCobrarD = "";
				this.productosCotizacion[0].Comentario = "";
				this.productoId = "";
				this.productosCotizacion[0].ivabs = "";
				this.productosCotizacion[0].ivad = "";
				this.mostrarAnadirProducto = true;
				this.notificarVendedor = false;
				this.consultarVendedor = false;
			} else {
				alert("Debe escoger a un vendedor.");
			}
		},
		notificarVendedorBroadcasting() {
			window.axios.post(
				process.env.VUE_APP_BACKEND_URL + "/tipoCambioMensaje",
				{
					message:
						"Cantidades para validar en cotización N°" +
						String(this.cotizacionIdBroadcasting).padStart(4, "0"),
					user_receptor: String(this.user_Receptor),
					cotizacion_id: String(this.cotizacionIdBroadcasting),
					check: "1"
				}
			);
		},
		notificarVendedorBroadcastingNuevoProducto() {
			window.axios.post(
				process.env.VUE_APP_BACKEND_URL + "/tipoCambioMensaje",
				{
					message:
						"Validar existencia y precio de producto " +
						this.productosCotizacion[0].Producto +
						" de cotización N°" +
						String(parseInt(this.CotizacionId[0].cotizacion_id)).padStart(
							4,
							"0"
						),
					user_receptor: String(this.user_Receptor),
					cotizacion_id: String(parseInt(this.CotizacionId[0].cotizacion_id)),
					producto: this.productosCotizacion[0].Producto,
					check: "1"
				}
			);
		}
	},
	computed: {
		cargarDataTabla() {
			let Matrix = [];
			for (let j = 0; j < this.CotizacionId.length; j++) {
				Matrix[j] = {
					id: parseInt(this.CotizacionId[j].id),
					Cantidad: parseFloat(this.CotizacionId[j].cantidad),
					cotizacion_id: parseInt(this.$route.params.id),
					Unidad: this.CotizacionId[j].precios[0].unidad,
					Rubro: this.CotizacionId[j].precios[0].producto.rubro,
					Producto: this.CotizacionId[j].precios[0].producto.producto,
					PrecioUnitarioBs:
						(this.tasadia * this.CotizacionId[j].total_usd) /
						parseFloat(this.CotizacionId[j].cantidad),
					PrecioUnitarioD:
						this.CotizacionId[j].total_usd /
						parseFloat(this.CotizacionId[j].cantidad),
					MontoCobrarBs: parseFloat(
						(this.CotizacionId[j].total_usd * this.tasadia).toFixed(2)
					),
					MontoCobrarD: this.CotizacionId[j].total_usd,
					Comentario: this.CotizacionId[j].comentario,
					Vendedor: this.CotizacionId[j].vendedores[0].user.name,
					Descuento: this.CotizacionId[j].precios[0].descuento,
					Idprecio: this.CotizacionId[j].precios_id,
					consultarVendedor: this.CotizacionId[j].notificar,
					ivabs: String(
						parseFloat(
							this.CotizacionId[j].total_usd *
							this.tasadia *
							this.CotizacionId[j].precios[0].iva
						) / 100
					),
					ivad:
						parseFloat(
							this.CotizacionId[j].total_usd *
							this.CotizacionId[j].precios[0].iva
						) / 100
				};
			}
			return Matrix;
		},
		opcionesVendedor() {
			let llenarVendedor = [];
			for (let i = 0; i < this.vendedoress.length; i++) {
				llenarVendedor.push(
					this.vendedoress[i].name +
					" " +
					"(" +
					this.vendedoress[i].pedidos.padStart(2, "0") +
					")"
				);
			}
			return llenarVendedor;
		},
		opcionesTelefono() {
			let llenarNumero = [];
			for (let i = 0; i < this.numeroCliente.length; i++) {
				llenarNumero.push(this.numeroCliente[i].numero_telefono_movil);
			}
			return llenarNumero;
		}
		/*     nombreCliente() {
	  let name = "";
	  for (let j = 0; j < this.usuarios.length; j++) {
		if (this.numeroTlf === this.usuarios[j].numero_telefono_movil) {
		  name = this.usuarios[j].name;
		  break;
		}
	  }
	  return name;
	}, */
	}
};
