export default {
	methods: {
		generarPDF() {
			let direccion = "";
			let dataPDF = {
				nombre: "",
				direccion: "",
				telefono: "",
				cotizacion: "",
				fecha: "",
				subtotalbs: "",
				subtotalusd: "",
				ivabs: "",
				ivad: "",
				totalbs: "",
				totalusd: "",
				exentoBs: "",
				exentoUsd: "",
				pedido: []
			};
			//Porcentaje de aumento o descuento
			let porcentaje = 0;

			if (this.tipoPorcentaje === "Aumento") {
				porcentaje = 1 / (1 - parseFloat(this.aumento) / 100);
			} else {
				porcentaje = 1 - parseFloat(this.descuento) / 100;
			}
			//Saca la fecha de hoy
			let today = new Date();
			let dd = String(today.getDate()).padStart(2, "0");
			let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
			let yyyy = today.getFullYear();

			today = dd + "/" + mm + "/" + yyyy;
			//Saca id de cotizacion
			for (let j = 0; j < this.usuarios.length; j++) {
				if (this.numeroTlf === this.usuarios[j].numero_telefono_movil) {
					direccion = this.usuarios[j].address;
					j = this.usuarios.length;
				}
			}
			//Data general a llenar
			dataPDF.nombre = this.nombreCliente;
			dataPDF.direccion = direccion;
			dataPDF.telefono = this.numeroTlf;
			dataPDF.cotizacion = String(this.numeroCotizacion).padStart(4, "0");
			dataPDF.fecha = today;
			dataPDF.subtotalbs =
				(parseFloat(this.ivabs) / (this.$store.state.ivaGeneral / 100)) *
				porcentaje;
			dataPDF.subtotalusd =
				(parseFloat(this.ivad) / (this.$store.state.ivaGeneral / 100)) *
				porcentaje;
			dataPDF.ivabs = parseFloat(this.ivabs) * porcentaje;
			dataPDF.ivad = parseFloat(this.ivad) * porcentaje;
			dataPDF.totalbs = parseFloat(this.totalbs) * porcentaje;
			dataPDF.totalusd = parseFloat(this.totalusd) * porcentaje;
			dataPDF.exentoBs =
				(parseFloat(this.subtotalbs) -
					parseFloat(this.ivabs) / (this.$store.state.ivaGeneral / 100)) *
				porcentaje;
			dataPDF.exentoUsd =
				(parseFloat(this.subtotalusd) -
					parseFloat(this.ivad) / (this.$store.state.ivaGeneral / 100)) *
				porcentaje;
			//llenar data pdf verifica si el monto se muestra en $
			for (let index = 0; index < this.productosCotizacionT.length; index++) {
				if (this.tipoPDF === "DolaresBolivares") {
					dataPDF.pedido.push({
						Cantidad: this.productosCotizacionT[index].Cantidad,
						Unidad: this.firstLetterUpperCase(
							this.productosCotizacionT[index].Unidad
						),
						PrecioUnitarioBs:
							parseFloat(this.productosCotizacionT[index].PrecioUnitarioBs) *
							porcentaje,
						PrecioUnitarioD:
							parseFloat(this.productosCotizacionT[index].PrecioUnitarioD) *
							porcentaje,
						MontoCobrarBs: parseFloat(
							this.productosCotizacionT[index].MontoCobrarBs * porcentaje
						),
						MontoCobrarD:
							parseFloat(this.productosCotizacionT[index].MontoCobrarD) *
							porcentaje,
						Producto: this.firstLetterUpperCase(
							this.productosCotizacionT[index].Producto
						)
					});
				} else if (this.tipoPDF === "Bolivares") {
					dataPDF.pedido.push({
						Cantidad: this.productosCotizacionT[index].Cantidad,
						Unidad: this.firstLetterUpperCase(
							this.productosCotizacionT[index].Unidad
						),
						PrecioUnitarioBs:
							parseFloat(this.productosCotizacionT[index].PrecioUnitarioBs) *
							porcentaje,
						MontoCobrarBs: parseFloat(
							this.productosCotizacionT[index].MontoCobrarBs * porcentaje
						),
						Producto: this.firstLetterUpperCase(
							this.productosCotizacionT[index].Producto.slice(0, 50)
						)
					});
				} else {
					dataPDF.pedido.push({
						Cantidad: String(
							parseFloat(this.productosCotizacionT[index].Cantidad)
						),
						Unidad: this.firstLetterUpperCase(
							this.productosCotizacionT[index].Unidad
						),
						PrecioUnitarioD:
							parseFloat(this.productosCotizacionT[index].PrecioUnitarioD) *
							porcentaje,
						MontoCobrarD:
							parseFloat(this.productosCotizacionT[index].MontoCobrarD) *
							porcentaje,
						Producto: this.firstLetterUpperCase(
							this.productosCotizacionT[index].Producto.slice(0, 50)
						)
					});
				}
			}
			// Paso variables al store
			this.$store.commit("crearDataPdf", JSON.parse(JSON.stringify(dataPDF)));
			// Me voy a otra ruta dependiendo de si esta marcado mostrarDolar o no
			let routeData;
			if (this.tipoPDF === "DolaresBolivares") {
				routeData = this.$router.resolve({
					name: "PdfUsdBs"
				});
			} else if (this.tipoPDF === "Bolivares") {
				routeData = this.$router.resolve({
					name: "PdfBs"
				});
			} else {
				routeData = this.$router.resolve({
					name: "PdfUsd"
				});
			}
			window.open(routeData.href, "_blank");
			this.mostrarPDF = false;
		}
	}
};
